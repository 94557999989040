import React from 'react';
import Layout from '@components/layout';
import Seo from '@components/seo';
import CtaMailCard from '@components/sections/ctaMailCard/CtaMailCard';
import Segment from '@components/segment';
import { THEME_LIGHT } from '@/src/common/consts/theme';
import { SOURCE_EARLY_ACCESS } from '@/src/common/consts/tracking';

const BrazePage = () => (
  <Layout
    lang="us"
    headerWitdh="header"
    text="Braze"
    dataTheme="white"
  >
    <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_STAGE_US} />
    <Seo title="Braze" language="en" />

    <CtaMailCard
      dataTheme={THEME_LIGHT}
      market="US"
      successPage="/early-access/granted/"
      source={SOURCE_EARLY_ACCESS}
    />
  </Layout>
);

export default BrazePage;
